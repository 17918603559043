import UIkit from 'uikit/dist/js/uikit';

import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);
window.Uikit = UIkit;

import.meta.glob([
    '../images/**',
]);

function loadDeferredStyles() {
    const noscript = document.getElementById('deferred-styles');
    if (noscript) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = noscript.getAttribute('data-style-link');
        document.head.appendChild(link);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    loadDeferredStyles();
});
// UIkit.modal.confirm('Bitte beachten Sie, dass diese Seite lediglich eine Vorschau oder Demo-Version ist und möglicherweise nicht wie erwartet funktioniert. Wir möchten darauf hinweisen, dass die offizielle und gültige Website unseres Unternehmens unter der Adresse www.eton.de zu finden ist. Wenn Sie auf der Suche nach aktuellen Informationen, Produkten oder Dienstleistungen sind, empfehlen wir Ihnen, unsere offizielle Website zu besuchen.\n' +
//     '\n' +
//     '\n' +
//     'Wir arbeiten kontinuierlich daran, unsere Produkte und Dienstleistungen zu verbessern und unseren Kunden die bestmögliche Erfahrung zu bieten. Bitte zögern Sie nicht, uns bei Fragen oder Anliegen zu kontaktieren. Wir sind immer bereit, Ihnen zu helfen und Ihnen alle Informationen zu geben, die Sie benötigen.\n' +
//     '\n' +
//     '\n' +
//     'Nochmals vielen Dank, dass Sie sich die Zeit genommen haben, unsere Vorschau-Seite zu besuchen. Wir hoffen, dass Sie die Informationen auf dieser Seite hilfreich fanden und freuen uns darauf, Ihnen auf unserer offiziellen Website unter www.eton.de den bestmöglichen Service bieten zu können.').then(function() {
//     console.log('Confirmed.')
// }, function () {
//     window.location.href = 'https://eton.de';
// });
